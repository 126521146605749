
import {computed, defineComponent, onUpdated, ref} from "vue";
import {useRouter} from "vue-router";
import {LoadEntity} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import EntityView from "@/components/base/common/EntityView.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import ProductForm from "@/views/product/ProductForm.vue";
import Swal from "sweetalert2";
import ProductService from "@/core/services/ProductService";
import TabNavigate from "@/components/base/common/TabNavigate.vue";

export default defineComponent({
  name: "Product",
  components: {TabNavigate, ProductForm, BaseModal, QuickAction, DateTimeFormat, EntityView},
  setup() {
    const router = useRouter();
    const store = useStore();
    const id = ref(router.currentRoute.value.params.id)
    const product = computed<any>(() => store.state.ProductModule.product)

    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Product Details', [
        {link: true, router: '/companies/' + product.value.company.id + "/products", text: 'View Company'},
        {link: false, router: '', text: 'View Product'}
      ])
    })
    return {
      slug: id,
      ...LoadEntity(Actions.LOAD_PRODUCT),
      product
    }
  },
  methods: {
    onProductSaved() {
      this.reloadData()
      const baseModal = this.$refs.productFormRef as typeof BaseModal
      baseModal.hideBaseModal()
    },
    deleteProduct(product) {
      console.log(product);
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await ProductService.delete(product.id).then(() => {
            this.$router.push({
              name: 'products'
            })
          })
        }
      })
    }
  }
})
